import React, {useEffect, useState} from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import CheckoutPage from './shop-components/checkout';
import FooterV2 from './global-components/footer-v2';
import sectiondata from "../data/sections.json";
import {Link} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {savePaymentMethod, saveShippingAddress} from "../Redux/Actions/cartActions";
import {createOrder} from "../Redux/Actions/OrderActions";
import {ORDER_CREATE_RESET} from "../Redux/Constants/OrderConstants";
import Message from "./LoadingError/Error";

const Sheckout = ({ history }) => {

    let publicUrl = process.env.PUBLIC_URL+'/'
    let imgattr = 'image'


    const cart = useSelector((state) => state.cart);
    const { shippingAddress } = cart;

    const [address, setAddress] = useState(shippingAddress.address);
    const [city, setCity] = useState(shippingAddress.city);
    const [postalCode, setPostalCode] = useState(shippingAddress.postalCode);
    const [country, setCountry] = useState(shippingAddress.country);
    const [paymentMethod, setPaymentMethod] = useState("PayPal");
    const orderCreate = useSelector((state) => state.orderCreate);
    const { order, success, error } = orderCreate;

    const dispatch = useDispatch();

    const submitHandler = () => {
        dispatch(saveShippingAddress({ address, city, postalCode, country }));
        dispatch(savePaymentMethod(paymentMethod));
    };

    const placeOrderHandler = () => {
        dispatch(
            createOrder({
                orderItems: cart.cartItems,
                shippingAddress: cart.shippingAddress,
                paymentMethod: cart.paymentMethod,
                itemsPrice: cart.itemsPrice,
                shippingPrice: cart.shippingPrice,
                taxPrice: cart.taxPrice,
                totalPrice: cart.totalPrice,
            })
        );
    };

    useEffect(() => {
        window.scrollTo(0, 0);

        if (success) {
            history.push(`/order/${order._id}`);
            dispatch({ type: ORDER_CREATE_RESET });
        }
    }, [history, dispatch, success, order]);

    // Calculate Price
    const addDecimals = (num) => {
        return (Math.round(num * 100) / 100).toFixed(2);
    };

    cart.itemsPrice = addDecimals(
        cart.cartItems.reduce((acc, item) => acc + item.price * item.qty, 0)
    );
    cart.shippingPrice = addDecimals(cart.itemsPrice > 100 ? 0 : 100);
    cart.taxPrice = addDecimals(Number((0.15 * cart.itemsPrice).toFixed(2)));
    cart.totalPrice = (
        Number(cart.itemsPrice) +
        Number(cart.shippingPrice) +
        Number(cart.taxPrice)
    ).toFixed(2);

    return (
        <>
            <Navbar />
            <PageHeader headertitle="Paiement" />

            <div className="checkout-page-area pd-top-50">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="checkout-form-wrap">
                                <div className="checkout-title">
                                    <div className="row">
                                        <div className="col-xl-5 col-lg-12 col-md-6">
                                            <h6>Adresse de livraison</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="checkout-form">
                                    <form className="riyaqas-form-wrap" onSubmit={submitHandler}>
                                        <div className="row custom-gutters-20">
                                            <div className="col-md-12">
                                                <div className="single-input-wrap">
                                                    <input type="text" className="single-input" value={address}
                                                           required
                                                           onChange={(e) => setAddress(e.target.value)} />
                                                    <label>Address</label>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="single-input-wrap">
                                                    <input type="text" className="single-input" value={city}
                                                           required
                                                           onChange={(e) => setCity(e.target.value)}/>
                                                    <label>City</label>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="single-input-wrap">
                                                    <input type="text" className="single-input" value={postalCode}
                                                           required
                                                           onChange={(e) => setPostalCode(e.target.value)}/>
                                                    <label>Postal code</label>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="single-input-wrap">
                                                    <input type="text" className="single-input" value={country}
                                                           required
                                                           onChange={(e) => setCountry(e.target.value)}
                                                    />
                                                    <label>Country</label>
                                                </div>
                                            </div>

                                            <div className="col-md-12 padding-top-50">
                                                <div className="checkout-title">
                                                    <h6>Méthode de paiement</h6>
                                                </div>
                                                <div className="">
                                                    <input className="single-input"
                                                           type="radio"
                                                           value={paymentMethod}
                                                           onChange={(e) => setPaymentMethod(e.target.value)}
                                                    />
                                                    <img src={publicUrl+"assets/img/checkout/1.png"} alt="img" />
                                                    <img src={publicUrl+"assets/img/checkout/2.png"} alt="img" />
                                                    <img src={publicUrl+"assets/img/checkout/3.png"} alt="img" />
                                                    <img src={publicUrl+"assets/img/checkout/4.png"} alt="img" />
                                                    <img src={publicUrl+"assets/img/checkout/5.png"} alt="img" />
                                                    {/*<li>
                                                            <input type="radio"
                                                                   value={paymentMethod}
                                                                   onChange={(e) => setPaymentMethod(e.target.value)} className="single-input" />
                                                            <a href="#">Cash On Delivery</a>
                                                        </li>
                                                        <li>
                                                            <input type="radio"
                                                                   value={paymentMethod}
                                                                   onChange={(e) => setPaymentMethod(e.target.value)} className="single-input" />
                                                            <a href="#">Bank Account Payment</a>
                                                        </li>*/}
                                                </div>
                                            </div>
                                            {cart.cartItems.length === 0 ? null : (
                                                <div className="col-12 text-right">
                                                    <button type="submit" className="btn btn-green" >Enregistrer adresse et paiement</button>
                                                </div>
                                            )}
                                            {error && (
                                                <div className="my-3 col-12">
                                                    <Message variant="alert-danger">{error}</Message>
                                                </div>
                                            )}
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-5">
                            <div className="checkout-form-product">

                                {cart.cartItems.length === 0 ? (
                                    <Message variant="alert-info mt-5">Your cart is empty</Message>
                                ) : (
                                    <>
                                        {cart.cartItems.map((item, index) => (

                                            <div className="order-table table-responsive" key={index}>
                                                <table className="table">
                                                    <tbody>
                                                    <tr>
                                                        <td>
                                                            <div className="media single-cart-product">
                                                                <div className="media-left">
                                                                    <img src={item.image} style={{ width:"20%" }} alt={item.name}  />
                                                                </div>
                                                                <div className="media-body">
                                                                    <span>{item.name}</span>
                                                                    <p>Quantité : {item.qty}</p>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="cart-product-price text-center">${item.qty * item.price}</td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>

                                        ))}
                                    </>
                                )}
                                {/* total */}
                                <div className="total-shapping-area-wrap">
                                    <div className="total-shapping-area">
                                        <div className="charge">
                                            <span>Produits :</span>
                                            <span className="amount float-right">${cart.itemsPrice}</span>
                                        </div>
                                        <div className="charge">
                                            <span>Adresse :</span>
                                            <span className="amount float-right">${cart.shippingPrice}</span>
                                        </div>
                                        <div className="charge">
                                            <span>Taxes :</span>
                                            <span className="amount float-right">${cart.taxPrice}</span>
                                        </div>
                                        <div className="total-amount">
                                            <span>Total:</span>
                                            <span className="amount float-right">${cart.totalPrice}</span>
                                        </div>
                                        <div className="col-md-6 text-right">
                                            <button type="submit" onClick={placeOrderHandler} className="btn btn-green" >Placer une commande</button>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <FooterV2 />

        </>
    );
}

export default Sheckout

