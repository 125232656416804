import React from "react";
import {HashRouter, Route, Router, Switch} from "react-router-dom";
import HomeV1 from "./components/home-v1";
import Shop from "./components/shop";
import ShopGrid from "./components/shop-grid";
import ShopDetails from "./components/shop-details";
import About from "./components/about";
import Contact from "./components/contact";
import Connexion from "./components/connexion";
import Inscription from "./components/inscription";
import Inscriptionn from "./components/inscriptionn";
import Recherche from "./components/recherche";
import Services from "./components/services";
import JobListing from "./components/job-listing";
import JobDetails from "./components/job-details";
import JobApply from "./components/job-apply";
import pricing from "./components/pricing";
import offer from "./components/offer";
import gallery from "./components/gallery";
import workprocessing from "./components/work-processing";
import faq from "./components/faq";
import Error from "./components/error";
import CommingSoon from "./components/comming-soon";
import Checkout from "./components/checkout";
import Order from "./components/order";
import { createBrowserHistory } from 'history'
import PrivateRouter from "./PrivateRouter";
import ProfileScreen from "./components/profile/ProfileScreen";

const newHistory = createBrowserHistory();

const App = () => {
  return (
    <Router history={newHistory} >

      <HashRouter basename="/">
        <div>
          <Switch>
            <Route path="/" component={HomeV1} exact />
            {/*<Route path="/search/:keyword" component={HomeV1} exact />
            <Route path="/page/:pagenumber" component={HomeV1} exact />
            <Route
                path="/search/:keyword/page/:pageNumber"
                component={HomeV1}
                exact
            />*/}

            <Route path="/shop" component={Shop} />
            <Route path="/shop-grid" component={ShopGrid} />
            <Route path="/shop-details/:id" component={ShopDetails} />

            <Route path="/about" component={About} />
            <Route path="/contact" component={Contact} />

            <Route path="/connexion" component={Connexion} />
            <Route path="/inscription" component={Inscription} />
            <Route path="/inscriptionn/:num" component={Inscriptionn} />

            <PrivateRouter path="/profile" component={ProfileScreen} />
            <PrivateRouter path="/checkout" component={Checkout} />
            <PrivateRouter path="/order/:id" component={Order} />

            <Route path="/recherche" component={Recherche} />
            <Route path="/services" component={Services} />

            <Route path="/job-listing" component={JobListing} />
            <Route path="/job-details" component={JobDetails} />
            <Route path="/job-apply" component={JobApply} />

            <Route path="/pricing" component={pricing} />
            <Route path="/offer" component={offer} />
            <Route path="/gallery" component={gallery} />
            <Route path="/work-processing" component={workprocessing} />
            <Route path="/faq" component={faq} />
            <Route path="/error" component={Error} />
            <Route path="/comming-soon" component={CommingSoon} />


          </Switch>
        </div>
      </HashRouter>

    </Router>
  );
};

export default App;
