import React, { useEffect, useState } from "react";
import Rating from "../components/shop-components/Rating";
import sectiondata from '../data/product.json';
import { Link } from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";

import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import FooterV2 from './global-components/footer-v2';
import {PRODUCT_CREATE_REVIEW_RESET} from "../Redux/Constants/ProductConstants";
import {createProductReview, listProductDetails} from "../Redux/Actions/ProductActions";
import {listCategoryDetails} from "../Redux/Actions/CategoryActions";
import moment from "moment";
import Message from "./LoadingError/Error";
import Loading from "./LoadingError/Loading";
import {addToCart, removefromcart} from "../Redux/Actions/cartActions";

const ShopDetials = ({ history, match }) => {

    let publicUrl = process.env.PUBLIC_URL+'/'
    let imgattr = 'image'
    let data = sectiondata.related_product

    const [qty, setQty] = useState(1);
    const [rating, setRating] = useState(0);
    const [comment, setComment] = useState("");

    const productId = match.params.id;
    const dispatch = useDispatch();

    const productDetails = useSelector((state) => state.productDetails);
    const { loading, error, product } = productDetails;

    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;
    const productReviewCreate = useSelector((state) => state.productReviewCreate);
    const {
        loading: loadingCreateReview,
        error: errorCreateReview,
        success: successCreateReview,
    } = productReviewCreate;

    const categoryDetails = useSelector((state) => state.categoryDetails);
    const { category } = categoryDetails;

    const cart = useSelector((state) => state.cart);
    const { cartItems } = cart;

    const total = cartItems.reduce((a, i) => a + i.qty * i.price, 0).toFixed(2);

    useEffect(() => {
        if (successCreateReview) {
            alert("Review Submitted");
            setRating(0);
            setComment("");
            dispatch({ type: PRODUCT_CREATE_REVIEW_RESET });
        }
        dispatch(listProductDetails(productId));
        dispatch(listCategoryDetails(product.categoryid))

    }, [dispatch, productId, successCreateReview, product.categoryid ]);

    const AddToCartHandle = (e) => {
        e.preventDefault();
        dispatch(addToCart(productId, qty));
    };

    const submitHandler = (e) => {
        e.preventDefault();
        dispatch(
            createProductReview(productId, {
                rating,
                comment,
            })
        );
    };

    const checkOutHandler = () => {
        history.push("/connexion?redirect=checkout");
    };

    const removeFromCartHandle = (id) => {
        dispatch(removefromcart(id));
    };


    return (
        <>
            <div>
                <Navbar />
                <PageHeader headertitle="Détails du produit" />

                <div className="cart-popup" id="cart-popup">
                    <div className="order-table table-responsive">

                        {cartItems.length === 0 ? (
                            <div className=" alert alert-info text-center mt-3">
                                Your cart is empty
                                <Link
                                    className="btn btn-success mx-5 px-5 py-3"
                                    to="/"
                                    style={{
                                        fontSize: "12px",
                                    }}
                                >
                                    SHOPPING NOW
                                </Link>
                            </div>
                        ) : (
                            <>
                                <div className="checkout-title text-center mb-0"><h6>Vos produits du panier ({cartItems.length})</h6></div>

                                {/* cartiterm */}
                                {cartItems.map((item, i) => (

                                    <table className="table">
                                        <tbody>
                                        <tr>
                                            <td>
                                                <div className="media single-cart-product" key={i}>
                                                    <div className="media-left">
                                                        <img src={item.image} alt={item.name} />
                                                    </div>
                                                    <div className="media-body">
                                                        <span>{item.name}</span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="cart-product-price text-center">{item.price}</td>
                                            <td className="text-center">
                                                <div className="quantity-wrap">
                                                    <div className="quantity">
                                                        <select
                                                            value={item.qty}
                                                            className="form-select form-control"
                                                            onChange={(e) =>
                                                                dispatch(addToCart(item.product, Number(e.target.value)))
                                                            }
                                                        >
                                                            {[...Array(item.countInStock).keys()].map((x) => (
                                                                <option key={x + 1} value={x + 1}>
                                                                    {x + 1}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="text-center">
                                                <div className="cart-close"
                                                     onClick={() => removeFromCartHandle(item.product)}
                                                >
                                                    <span className="ti-close" />
                                                </div>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>

                                ))}

                                {/* End of cart iterms */}
                                <div className="total-shapping-area-wrap">
                                    <div className="total-shapping-area">
                                        <div className="total-amount">
                                            <span>Total:</span>
                                            <span className="amount float-right">${total}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="btn-wrapper text-center pd-top-100 riyaqas-nav">
                                    <Link className="btn btn-green pd-top-50" style={{ marginRight:"80px" }} to="/">Continuer les courses</Link>
                                    {total > 0 && (
                                        <button className="btn btn-green" onClick={checkOutHandler} >Procéder au paiement</button>
                                    )}
                                </div>

                            </>
                        )}
                    </div>
                </div>





                <div>
                    <div className="single-product-area pd-top-120">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-7">
                                    <div className="product-thumbnail-wrapper">
                                        <div className="row custom-gutters-16">
                                            <div className="col-3">
                                                <div className="product-thumbnail-carousel">
                                                    <div className="single-thumbnail-item">
                                                        <img src={product.image} alt={product.name} />
                                                    </div>
                                                    <div className="single-thumbnail-item">
                                                        <img src={product.image2} alt={product.name}  />
                                                    </div>
                                                    <div className="single-thumbnail-item">
                                                        <img src={product.image3} alt={product.name}  />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-9">
                                                <div className="single-thumbnail-slider">
                                                    <div className="slider-item">
                                                        <img src={product.image}  alt={product.name} />
                                                    </div>
                                                    <div className="slider-item">
                                                        <img src={product.image2}  alt={product.name} />
                                                    </div>
                                                    <div className="slider-item">
                                                        <img src={product.image3}  alt={product.name} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                    <div className="product-description">
                                        <Rating
                                            value={product.rating}
                                            text={`${product.numReviews} reviews`}
                                        />
                                        <h4 className="title">{product.name}</h4>
                                        <div className="price">
                                            <span>${product.price}</span>
                                        </div>
                                        <p className="content">{product.description}</p>

                                        <h6 className="title">Status</h6>
                                        {product.countInStock > 0 ? (
                                            <span>In Stock</span>
                                        ) : (
                                            <span>unavailable</span>
                                        )}

                                        <div className="quantity-wrap">
                                            {product.countInStock > 0 ? (
                                                <>
                                                    <div className="quantity float-left">
                                                        <h6 className="title">Quantity</h6>
                                                        <select
                                                            value={qty}
                                                            onChange={(e) => setQty(e.target.value)}
                                                            className="form-select form-control"
                                                        >
                                                            {[...Array(product.countInStock).keys()].map(
                                                                (x) => (
                                                                    <option key={x + 1} value={x + 1}>
                                                                        {x + 1}
                                                                    </option>
                                                                )
                                                            )}
                                                        </select>
                                                    </div>
                                                    <button
                                                        onClick={AddToCartHandle}
                                                        className="btn btn-gray ml-xl-5 ml-sm-4 cart-btn"
                                                        id="cart-btn"
                                                    >
                                                        Add To Cart <i className="fa fa-shopping-cart" />
                                                    </button>
                                                </>
                                            ) : null}

                                        </div>

                                        <div className="category">
                                            <span>Category: </span>
                                            {/*<a href="#">{category.name}</a>*/}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="product-information">
                                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                                            <li className="nav-item">
                                                <a className="nav-link active" id="descr-tab" data-toggle="tab" href="#descr" role="tab" aria-controls="descr" aria-selected="true">Description</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" id="reviews-tab" data-toggle="tab" href="#reviews" role="tab" aria-controls="reviews" aria-selected="false">Reviews</a>
                                            </li>
                                        </ul>
                                        <div className="tab-content" id="myTabContent">
                                            <div className="tab-pane fade show active" id="descr" role="tabpanel" aria-labelledby="descr-tab">
                                                <div className="row">
                                                    <div className="col-lg-11">
                                                        <div className="description-tab-content">
                                                            <p>{product.description}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="tab-pane fade" id="reviews" role="tabpanel" aria-labelledby="reviews-tab">
                                                <div className="row">
                                                    <div className="col-lg-10">
                                                        {product.reviews.map((review) => (
                                                            <div className="review-area">
                                                                <div className="single-review">
                                                                    <div className="media">
                                                                        <img className="media-left" src={publicUrl+"assets/img/blog/comments/1.png"} alt="img" />
                                                                        <div className="media-body" key={review._id}>
                                                                            <span>{review.name}</span>
                                                                            <Rating value={review.rating} />
                                                                            <span>{moment(review.createdAt).calendar()}</span>
                                                                            <p>CEO</p>
                                                                        </div>
                                                                    </div>
                                                                    <p>{review.comment}</p>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                                <h6 className="review-title">Your Reviews</h6>

                                                <div className="col-md-6">
                                                    <h6>Donnez votre avis</h6>
                                                    <div className="my-4">
                                                        {loadingCreateReview && <Loading />}
                                                        {errorCreateReview && (
                                                            <Message variant="alert-danger">
                                                                {errorCreateReview}
                                                            </Message>
                                                        )}
                                                    </div>
                                                    {userInfo ? (
                                                        <form onSubmit={submitHandler}>
                                                            <div className="my-4">
                                                                <strong>Rating</strong>
                                                                <select
                                                                    value={rating}
                                                                    onChange={(e) => setRating(e.target.value)}
                                                                    className="col-12 bg-light p-3 mt-2 border-0 rounded"
                                                                >
                                                                    <option value="">Select...</option>
                                                                    <option value="1">1 - insatisfait</option>
                                                                    <option value="2">2 - moyennement satisfait</option>
                                                                    <option value="3">3 - Bon</option>
                                                                    <option value="4">4 - Très bon</option>
                                                                    <option value="5">5 - Excellent</option>
                                                                </select>
                                                            </div>
                                                            <div className="my-4">
                                                                <strong>Comment</strong>
                                                                <textarea
                                                                    row="3"
                                                                    value={comment}
                                                                    onChange={(e) => setComment(e.target.value)}
                                                                    className="col-12 bg-light p-3 mt-2 border-0 rounded"
                                                                ></textarea>
                                                            </div>
                                                            <div className="my-3">
                                                                <button
                                                                    disabled={loadingCreateReview}
                                                                    className="btn btn-green"
                                                                >
                                                                    SUBMIT
                                                                </button>
                                                            </div>
                                                        </form>
                                                    ) : (
                                                        <div className="my-3">
                                                            <Message variant={"alert-warning"}>
                                                                Please{" "}
                                                                <Link to="/connexion">
                                                                    " <strong>Login</strong> "
                                                                </Link>{" "}
                                                                to write a review{" "}
                                                            </Message>
                                                        </div>
                                                    )}
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="shop-page-area single-shop-related-product mg-top-105">
                        <div className="container">
                            <div className="section-title">
                                <h2 className="title">Vous pourriez aimer</h2>
                            </div>
{/*
                            <div className="row custom-gutters-16">
                                { data.items.map( ( item, i )=>
                                    <div key={ i } className="col-lg-3 col-sm-6">
                                        <div className="single-shop">
                                            <div className="thumb">
                                                <a className={"product-badge btn-blue "+item.sticker  } href="#">New</a>
                                                <img src={ publicUrl+item.image } alt={ imgattr } />
                                                <div className="cart-btn">
                                                    <div className="cart-btn-wrap">
                                                        <Link className="btn btn-red" to="/shop" >Add to card <i className="fa fa-shopping-cart" /></Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="content">
                                                <div className="rating">
                                                    <i className="fa fa-star" />
                                                    <i className="fa fa-star" />
                                                    <i className="fa fa-star" />
                                                    <i className="fa fa-star" />
                                                    <i className="fa fa-star" />
                                                </div>
                                                <Link to="/shop" >{ item.name }</Link>
                                                <div className="price">
                                                    <span>{ item.newerprice }</span><del>{ item.olderprice }</del>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) }


                            </div>
*/}
                        </div>
                    </div>
                </div>

                <FooterV2 />
            </div>

        </>
    );
}

export default ShopDetials

