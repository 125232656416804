export const CATEGORY_LIST_REQUEST = "CATEGORY_LIST_REQUEST";
export const CATEGORY_LIST_SUCCESS = "CATEGORY_LIST_SUCCESS";
export const CATEGORY_LIST_FAIL = "CATEGORY_LIST_FAIL";

export const CATEGORY_DETAILS_REQUEST = "CATEGORY_DETAILS_REQUEST";
export const CATEGORY_DETAILS_SUCCESS = "CATEGORY_DETAILS_SUCCESS";
export const CATEGORY_DETAILS_FAIL = "CATEGORY_DETAILS_FAIL";

export const CATEGORY_CREATE_REVIEW_REQUEST = "CATEGORY_CREATE_REVIEW_REQUEST";
export const CATEGORY_CREATE_REVIEW_SUCCESS = "CATEGORY_CREATE_REVIEW_SUCCESS";
export const CATEGORY_CREATE_REVIEW_FAIL = "CATEGORY_CREATE_REVIEW_FAIL";
export const CATEGORY_CREATE_REVIEW_RESET = "CATEGORY_CREATE_REVIEW_RESET";
