import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import Services from './section-components/services';
import PricingTable from './section-components/pricing-table';
import TestimonialV2 from './section-components/testimonial-v2';
import Subscribe from './section-components/subscribe';
import FooterV1 from './global-components/footer-v1';

const Service = () => {
    return <div>
        <Navbar />
        <PageHeader headertitle="Services offerts" />
        <Services customclass="pd-top-112" />
        {/*<PricingTable />
        <TestimonialV2 />*/}
        <Subscribe />
        <FooterV1 />
    </div>
}

export default Service

