import React, {useEffect, useState} from 'react';
import Navbar from './global-components/navbar';
import Footer_V2 from "./global-components/footer-v2";
import sectiondata from "../data/sections.json";
import {Link, NavLink} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {register} from "../Redux/Actions/userActions";

const Inscription = ({ location, history }) => {
    let publicUrl = process.env.PUBLIC_URL+'/'
    let imgattr = 'image'

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const dispatch = useDispatch();
    const redirect = location.search ? location.search.split("=")[1] : "/";

    const userRegister = useSelector((state) => state.userRegister);
    const { error, loading, userInfo } = userRegister;

    useEffect(() => {
        window.scrollTo(0, 0);
        if (userInfo) {
            history.push(redirect);
        }
    }, [userInfo, history, redirect]);

    const submitHandler = (e) => {
        e.preventDefault();
        dispatch(register(name, email, password));
    };

    return (
        <>
            <Navbar />

            <div className="sba-pricing-area pd-top-112">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-6 col-lg-9">
                            <div className="section-title text-center">
                                <h2 className="title">
                                    { sectiondata.pricingtable.sectiontitle } <span>{sectiondata.pricingtable.sectiontitle_color}</span>
                                </h2>
                                <p>
                                    {sectiondata.pricingtable.short_description}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row custom-gutters-20 justify-content-center">
                        {sectiondata.pricingtable.tables.map( ( item, i ) =>
                            <div key={i} className="col-xl-3 col-sm-6">
                                <div className="single-pricing text-center">
                                    <h6 className="title">{item.title}</h6>
                                    <div className="thumb">
                                        <img src={publicUrl+item.icon} alt={imgattr} />
                                    </div>
                                    <h3 className="price">{item.price} <span>{item.duration}</span></h3>
                                    <ul>
                                        {item.features.map( ( feature, i  ) =>
                                            <li key={i}>{feature}</li>
                                        )}
                                    </ul>
                                    <Link className="btn btn-white btn-rounded"  to={ item.url }>Souscrire</Link>
                                </div>
                            </div>
                        )}

                    </div>
                </div>
            </div>


            <div className="pricing-page-area pd-top-112">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-6">
                            <div className="section-title text-center">
                                <h2 className="title">
                                    Vous êtes clients du site ? <span>Inscrivez-vous pour vos achats et vos transactions</span>
                                </h2>
                                <p>
                                    Payez différents produits , faites vous accompagner dans vos besoins de transactions d'argent dans le monde
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-xl-6" style={{ animation:"1s 1s fadeInUp both" }}>
                            <div className="col-md-12 text-center"  style={{ fontSize:"18px" }}>
                                CREEZ VOTRE COMPTE
                                <hr style={{ width:"120px" }} />
                            </div>
                            <br/>
                            <form className="riyaqas-form-wrap mt-0 mt-lg-0" onSubmit={submitHandler}>
                                <div className="row custom-gutters-16 justify-content-center">
                                    <div className="col-md-8">
                                        <div className="single-input-wrap">
                                            <input type="text" className="single-input form-control" value={name}
                                                   onChange={(e) => setName(e.target.value)}/>
                                            <label>Nom et prénom*</label>
                                        </div>
                                    </div>
                                    <div className="col-md-8">
                                        <div className="single-input-wrap">
                                            <input type="email" className="single-input" value={email}
                                                   onChange={(e) => setEmail(e.target.value)}/>
                                            <label>E-mail*</label>
                                        </div>
                                    </div>
                                    <div className="col-md-8">
                                        <div className="single-input-wrap">
                                            <input type="password" className="single-input" value={password}
                                                   onChange={(e) => setPassword(e.target.value)}/>
                                            <label>Mot de passe :*</label>
                                        </div>
                                    </div>
                                    <div className="col-md-8 text-center">
                                        <button type="submit" className="btn btn-red mt-0">Créer votre compte</button>
                                    </div>
                                </div>
                            </form>
                            <div className="col-md-12 text-center mg-top-45">
                                Vous avez déjà un compte ? <NavLink to={redirect ? `/connexion?redirect=${redirect}` : "/connexion"}>Connectez-vous</NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer_V2 />
        </>
    );
}

export default Inscription

