import React, { Component , useState} from 'react';
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {logout} from "../../Redux/Actions/userActions";
import {addToCart, removefromcart} from "../../Redux/Actions/cartActions";

const Navbar = () => {

    let publicUrl = process.env.PUBLIC_URL+'/'
    let imgattr = 'logo'
    let anchor = '#'

    const [keyword, setKeyword] = useState();
    const dispatch = useDispatch();
    let history = useHistory();

    const cart = useSelector((state) => state.cart);
    const { cartItems } = cart;

    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;
    const total = cartItems.reduce((a, i) => a + i.qty * i.price, 0).toFixed(2);

    const logoutHandler = () => {
        dispatch(logout());
    };

    const removeFromCartHandle = (id) => {
        dispatch(removefromcart(id));
    };

    const checkOutHandler = () => {
        history.push("/connexion?redirect=checkout");
    };

    const submitHandler = (e) => {
        e.preventDefault();
        if (keyword.trim()) {
            history.push(`/search/${keyword}`);
        } else {
            history.push("/");
        }
    };

    return (
        <div>
            <nav className="navbar navbar-area navbar-expand-lg nav-style-01">
                <div className="container nav-container">
                    <div className="responsive-mobile-menu">
                        <div className="logo-wrapper mobile-logo">
                            <a href={anchor} className="logo">
                                <img style={{ width:"40%" }} src={publicUrl+ 'assets/img/logo.png' } alt={imgattr} />
                            </a>
                        </div>
                        <div className="nav-right-content">
                            <ul>
                                <li className="search">
                                    <i className="ti-search" />
                                </li>
                            </ul>
                        </div>
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#Riyaqas_main_menu"
                                aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggle-icon">
                                    <span className="line"></span>
                                    <span className="line"></span>
                                    <span className="line"></span>
                                </span>
                        </button>
                    </div>
                    <div className="collapse navbar-collapse" id="Riyaqas_main_menu">
                        <div className="logo-wrapper desktop-logo">
                            <a href="#" className="logo">
                                <img style={{ width:"40%" , marginRight:"-100px" }} src={publicUrl + 'assets/img/logo.png'} alt={imgattr}/>
                            </a>
                        </div>
                        <ul className="navbar-nav">

                            <li>
                                <Link to="/">Boutiques</Link>
                            </li>
                            <li>
                                <Link to="/job-listing">Services</Link>
                            </li>
                            <li><Link to="/about">A propos</Link></li>
                            <li>
                                <Link to="/contact">contact</Link>
                            </li>

                            {userInfo ? (

                                <>
                                    <li className="menu-item-has-children">
                                        <i className="fa fa-user"></i>
                                        <Link to={'#'}>{ userInfo.name }</Link>
                                        <ul className="sub-menu">
                                            <li><Link to="/profile">Profile</Link></li>
                                            <li><Link to="#" onClick={logoutHandler}>Log out</Link></li>
                                        </ul>
                                    </li>

                                </>

                            ) : (

                                <>
                                    <li>
                                        <Link to="/connexion">Connexion</Link>
                                    </li>
                                    <li>
                                        <Link to="/inscription">Inscription</Link>
                                    </li>
                                </>

                            )}



                        </ul>
                    </div>
                    <div className="nav-right-content">
                        <ul>
                            <li className="search">
                                <i className="ti-search" />
                            </li>

                            <li className="cart">
                                <div className="notification">
                                    <a href="#">
                                        <i className="la la-shopping-cart" />
                                        <span className="notification-count riyaqas-cart-count">{cartItems.length}</span>
                                    </a>
                                </div>
                                <div className="widget_shopping_cart_content">
                                    <ul>
                                        {cartItems?.map((item, i) => (

                                            <li key={i}>
                                                <div className="media">
                                                    <div className="media-left">
                                                        <img src={item.image} style={{ width:"90px" , height:"90px" }} alt={item.name} />
                                                    </div>
                                                    <div className="media-body">
                                                        <Link className="title" to={`/shop-details/${item._id}`}>{item.name} </Link>
                                                        <p>Quantity: {item.qty} </p>
                                                        <span className="price">{item.price} </span>
                                                    </div>
                                                </div>
                                                <a className="remove-product" href="#"
                                                   onClick={() => removeFromCartHandle(item.product)}
                                                ><span className="ti-close" /></a>
                                            </li>

                                        ))}
                                    </ul>
                                    <p className="total offset-md-0">
                                        <strong>Total :</strong>
                                        <span className="amount">
                                              <span className="woocommerce-Price-currencySymbol">$</span>{total}
                                        </span>
                                    </p>
                                    <p className="buttons">
                                        {total > 0 && (
                                            <button onClick={checkOutHandler} className="button">Procéder au paiement</button>
                                        )}
                                    </p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>

            <div className="body-overlay" id="body-overlay"></div>
            <div className="search-popup" id="search-popup">
                <form onSubmit={submitHandler} className="search-form">
                    <div className="form-group">
                        <input type="search" className="form-control" placeholder="Search....." onChange={(e) => setKeyword(e.target.value)}/>
                    </div>
                    <button type="submit" className="submit-btn"><i className="fa fa-search"></i></button>
                </form>
            </div>
        </div>
    );

}


export default Navbar