import React from 'react';
import Navbar from './global-components/navbar';
import Subscribe from './section-components/subscribe';
import FooterV2 from './global-components/footer-v2';
import Footer_v1 from "./global-components/footer-v1";
import ShopGridPage from "./shop-components/shop-grid-page";
import SliderV3 from "./section-components/slider-v3";

const Home_V1 = ({ match }) => {
    window.scrollTo(0, 0);
    const keyword = match.params.keyword;
    const pagenumber = match.params.pagenumber;
    return <div>
        <Navbar  />
        <SliderV3 />
        <ShopGridPage  keyword={keyword} pagenumber={pagenumber} />
        <Subscribe />
        <FooterV2 />
    </div>
}

export default Home_V1

