import React, { Component } from 'react';
import sectiondata from '../../data/sections.json';


class Hosting extends Component {

    render() {
        let publicUrl = process.env.PUBLIC_URL+'/'
        let imgattr = 'image'
        let data = sectiondata.about
        let customclass = this.props.customclass ? this.props.customclass : ''

    return <div>
            <div className="hosting-type-area pd-top-120">
  <div className="container">
    <div className="row">
      <div className="col-lg-3">
        <ul className="nav nav-tabs sb-hosting-tabs">
          <li className="nav-item">
            <a className="nav-link active" data-toggle="tab" href="#d_tabs_1"><img  style={{ width:"15%" }} src={publicUrl+"assets/img/centrebon.png"} alt="img" />Centre hospitalier</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" data-toggle="tab" href="#d_tabs_2"><img style={{ width:"15%" }}  src={publicUrl+"assets/img/centrebon.png"} alt="img" />Pharmacie</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" data-toggle="tab" href="#d_tabs_3"><img  style={{ width:"15%" }}  src={publicUrl+"assets/img/centrebon.png"} alt="img" />Clinique</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" data-toggle="tab" href="#d_tabs_4"><img style={{ width:"15%" }}  src={publicUrl+"assets/img/centrebon.png"} alt="img" />Assurance</a>
          </li>
        </ul>
      </div>
      <div className="col-lg-9">
        <div className="tab-content">
          <div className="tab-pane fade show active" id="d_tabs_1">
            <div className="single-hosting-content">
              <div className="row">
                <div className="col-lg-3">
                  <div className="section-title style-two ">
                    <h6 className="title">Centre <span>Hospitalier</span></h6>
                  </div>
                  <p>Liste des centres disponibles dans nos bases de données et près de vous pour vous aider</p>
                </div>
                <div className="col-lg-4">
                  <div className="thumb">
                    <img src={publicUrl+"assets/img/back.webp"} alt="img" />
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="riyaqas-check-list-wrap">
                    <div className="riyaqas-check-list media">
                      <img className="media-left" src={publicUrl+"assets/img/icons/check3.png"} alt="check" />
                      <span>Hopital des soeurs de logpom</span>
                    </div>
                    <div className="riyaqas-check-list media">
                      <img className="media-left" src={publicUrl+"assets/img/icons/check3.png"} alt="check" />
                      <span>Hopital général</span>
                    </div>
                    <div className="riyaqas-check-list media">
                      <img className="media-left" src={publicUrl+"assets/img/icons/check3.png"} alt="check" />
                      <span>Hopital de marie joie</span>
                    </div>
                    <div className="riyaqas-check-list media">
                      <img className="media-left" src={publicUrl+"assets/img/icons/check3.png"} alt="check" />
                      <span>Hopital central de Messassi</span>
                    </div>
                    <div className="riyaqas-check-list media">
                      <img className="media-left" src={publicUrl+"assets/img/icons/check3.png"} alt="check" />
                      <span>Hopital de l'espérance</span>
                    </div>
                    <div className="riyaqas-check-list media">
                      <img className="media-left" src={publicUrl+"assets/img/icons/check3.png"} alt="check" />
                      <span>Hopital de Jesus guérisseur</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="tab-pane fade" id="d_tabs_2">
            <div className="single-hosting-content">
              <div className="row">
                <div className="col-lg-3">
                  <div className="section-title style-two ">
                    <h6 className="title">Pharmacie de<span> santé</span></h6>
                  </div>
                  <p>Achetez vos médicaments en tout temps en étant rassuré des pharmcies que vous pouvez trouver en utilisant notre système</p>
                </div>
                <div className="col-lg-4">
                  <div className="thumb">
                    <img src={publicUrl+"assets/img/back.webp"} alt="img" />
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="riyaqas-check-list-wrap">
                    <div className="riyaqas-check-list media">
                      <img className="media-left" src={publicUrl+"assets/img/icons/check3.png"} alt="check" />
                      <span>Pharmacie de la rive</span>
                    </div>
                    <div className="riyaqas-check-list media">
                      <img className="media-left" src={publicUrl+"assets/img/icons/check3.png"} alt="check" />
                      <span>Pharmcie de l'espérance</span>
                    </div>
                    <div className="riyaqas-check-list media">
                      <img className="media-left" src={publicUrl+"assets/img/icons/check3.png"} alt="check" />
                      <span>pharmacie de l'espoir</span>
                    </div>
                    <div className="riyaqas-check-list media">
                      <img className="media-left" src={publicUrl+"assets/img/icons/check3.png"} alt="check" />
                      <span>Pharmacie d'Emana</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="tab-pane fade" id="d_tabs_3">
            <div className="single-hosting-content">
              <div className="row">
                <div className="col-lg-3">
                  <div className="section-title style-two">
                    <h6 className="title">Clinique de <span>Santé</span></h6>
                  </div>
                  <p>Les cliniques à soins rapides enregistrés dans nos bases de données</p>
                </div>
                <div className="col-lg-4">
                  <div className="thumb">
                    <img src={publicUrl+"assets/img/back.webp"} alt="img" />
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="riyaqas-check-list-wrap">
                    <div className="riyaqas-check-list media">
                      <img className="media-left" src={publicUrl+"assets/img/icons/check3.png"} alt="check" />
                      <span>Clinique XYZ</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="tab-pane fade" id="d_tabs_4">
            <div className="single-hosting-content">
              <div className="row">
                <div className="col-lg-3">
                  <div className="section-title style-two">
                    <h6 className="title">Assurance</h6>
                  </div>
                  <p>Assurance multiples et autres</p>
                </div>
                <div className="col-lg-4">
                  <div className="thumb">
                    <img src={publicUrl+"assets/img/back.webp"} alt="img" />
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="riyaqas-check-list-wrap">
                    <div className="riyaqas-check-list media">
                      <img className="media-left" src={publicUrl+"assets/img/icons/check3.png"} alt="check" />
                      <span>Activa Sarl</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="tab-pane fade" id="d_tabs_5">
            <div className="single-hosting-content">
              <div className="row">
                <div className="col-lg-3">
                  <div className="section-title style-two">
                    <h6 className="title">Cloud <span>Hosting</span></h6>
                  </div>
                  <p>Our managed cloud hosting platform There are many variations passages of Lorem available, but the majority have suffered alteration in some form, by injected humour</p>
                  <p>Cloud hosting randomised words which don't look even slightly believable.</p>
                </div>
                <div className="col-lg-4">
                  <div className="thumb">
                    <img src={publicUrl+"assets/img/others/20.png"} alt="img" />
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="riyaqas-check-list-wrap">
                    <div className="riyaqas-check-list media">
                      <img className="media-left" src={publicUrl+"assets/img/icons/check3.png"} alt="check" />
                      <span>Technical complexities, letting you focus</span>
                    </div>
                    <div className="riyaqas-check-list media">
                      <img className="media-left" src={publicUrl+"assets/img/icons/check3.png"} alt="check" />
                      <span>Powered by top of the line enterprise</span>
                    </div>
                    <div className="riyaqas-check-list media">
                      <img className="media-left" src={publicUrl+"assets/img/icons/check3.png"} alt="check" />
                      <span>Manage web apps more collaboratively</span>
                    </div>
                    <div className="riyaqas-check-list media">
                      <img className="media-left" src={publicUrl+"assets/img/icons/check3.png"} alt="check" />
                      <span>We are committed to deliver fast</span>
                    </div>
                    <div className="riyaqas-check-list media">
                      <img className="media-left" src={publicUrl+"assets/img/icons/check3.png"} alt="check" />
                      <span>Apps more collaboratively.</span>
                    </div>
                    <div className="riyaqas-check-list media">
                      <img className="media-left" src={publicUrl+"assets/img/icons/check3.png"} alt="check" />
                      <span>Powered by top of the line enterprise</span>
                    </div>
                    <div className="riyaqas-check-list media mb-0">
                      <img className="media-left" src={publicUrl+"assets/img/icons/check3.png"} alt="check" />
                      <span>Manage web apps more collaboratively</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>    
    </div>
  </div>
</div>

        </div>
    }
}

export default Hosting