import React, { Component } from 'react';
import widgetdata from "../../data/widgetdata";
import {Link, NavLink} from "react-router-dom";
import sectiondata from "../../data/sections";
import parse from "html-react-parser";
import footerdata from "../../data/footerdata";

class Contact_Form extends Component {

    render() {
        let anchor = '#'
        let imgalt = 'image'
        let publicUrl = process.env.PUBLIC_URL+'/'
   
    return <div>
            <div className="contact-form-area pd-top-190">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6 connectbox" style={{ animation:"4s 1s fadeInUp both" }}>
                            <div className="col-md-12 text-center"  style={{ fontSize:"18px" }}>
                                CREEZ VOTRE COMPTE
                                <hr style={{ width:"120px" }} />
                            </div>
                            <br/>
                            <form className="riyaqas-form-wrap mt-0 mt-lg-0">
                                <div className="row custom-gutters-16">

                                    <div className="col-md-6">
                                        <div className="single-input-wrap">
                                            <input type="text" className="single-input"/>
                                            <label>Nom*</label>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="single-input-wrap">
                                            <input type="text" className="single-input"/>
                                            <label>Prénom*</label>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="single-input-wrap">
                                            <input type="email" className="single-input"/>
                                            <label>E-mail*</label>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="single-input-wrap">
                                            <input type="number" className="single-input"/>
                                            <label>Téléphone*</label>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="single-input-wrap">
                                            <input type="text" className="single-input"/>
                                            <label>Statut :*</label>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="single-input-wrap">
                                            <input type="file" className="single-input"/>
                                            <label>Photo de profil*</label>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="single-input-wrap">
                                            <input type="text" className="single-input"/>
                                            <label>Mot de passe :*</label>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="single-input-wrap">
                                            <input type="text" className="single-input"/>
                                            <label>Confirmer le mot de passe*</label>
                                        </div>
                                    </div>
                                    <div className="col-md-12 text-center form-control">
                                        <a className="btn btn-red mt-0" href={anchor}>Créer votre compte</a>
                                    </div>
                                </div>
                            </form>
                            <div className="col-md-12 text-center form-control">
                                Vous avez déjà un compte ? <NavLink to="/connexion" className="aconnect">Connectez-vous</NavLink>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
     }
}

export default Contact_Form

