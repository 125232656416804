import React, { Component } from 'react';
import widgetdata from "../../data/widgetdata";
import {Link, NavLink} from "react-router-dom";
import sectiondata from "../../data/sections";
import parse from "html-react-parser";
import footerdata from "../../data/footerdata";

class Contact_Form extends Component {

    render() {
        let anchor = '#'
        let imgalt = 'image'
        let publicUrl = process.env.PUBLIC_URL+'/'

        return

        <div>
            <div className="contact-form-area pd-top-190">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-4 connectbox" style={{ animation:"1s 1s fadeInUp both" }}>
                            <div className="col-md-12 text-center" style={{ fontSize:"18px" }}>
                                CONNECTEZ-VOUS
                                <hr style={{ width:"120px" }} />
                            </div>
                            <br/>
                            <form className="riyaqas-form-wrap mt-0 mt-lg-0">
                                <div className="row custom-gutters-16">

                                    <div className="col-md-12">
                                        <div className="single-input-wrap">
                                            <input type="e-mail" className="single-input" required={1}/>
                                            <label>E-mail*</label>
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="single-input-wrap">
                                            <input type="password" className="single-input"/>
                                            <label>Mot de passe :*</label>
                                        </div>
                                    </div>
                                    <div className="col-md-12 text-right">
                                        <a href={"/mdp"} className="aconnect">Mot de passe oublié ?</a>
                                    </div>
                                    <div className="col-md-12 pd-top-20 text-center form-control">
                                        <a className="btn btn-red mt-0 form-control" href={anchor}>Connexion</a>
                                    </div>
                                    <div className="col-md-12 text-center">
                                        se connecter avec
                                    </div>
                                    <div className="col-md-12 text-center">
                                        <div className="row">
                                            <div className="col-md-6 text-right">
                                                <i style={{ fontSize:"40px" }} className="fa fa-facebook" />
                                            </div>
                                            <div className="col-md-6 text-left">
                                                <i style={{ fontSize:"40px" }} className="fa fa-google" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 text-center form-control">
                                        Vous n'avez pas de compte ? <NavLink to="/inscription" className="aconnect">Inscrivez-vous dès maintenant</NavLink>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}

export default Contact_Form

