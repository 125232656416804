import React, {useEffect, useState} from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import FooterV2 from './global-components/footer-v2';
import { useDispatch, useSelector } from "react-redux";
import { PayPalButton } from "react-paypal-button-v2";
import Message from "./LoadingError/Error";
import {getOrderDetails, payOrder} from "../Redux/Actions/OrderActions";
import {ORDER_PAY_RESET} from "../Redux/Constants/OrderConstants";
import axios from "axios";
import {Link} from "react-router-dom";
import Loading from "./LoadingError/Loading";
import moment from "moment";

const Order = ({ match }) => {

    window.scrollTo(0, 0);
    const [sdkReady, setSdkReady] = useState(false);
    const orderId = match.params.id;
    const dispatch = useDispatch();

    const orderDetails = useSelector((state) => state.orderDetails);
    const { order, loading, error } = orderDetails;
    const orderPay = useSelector((state) => state.orderPay);
    const { loading: loadingPay, success: successPay } = orderPay;

    if (!loading) {
        const addDecimals = (num) => {
            return (Math.round(num * 100) / 100).toFixed(2);
        };

        order.itemsPrice = addDecimals(
            order.orderItems.reduce((acc, item) => acc + item.price * item.qty, 0)
        );
    }

    useEffect(() => {
        const addPayPalScript = async () => {
            const { data: clientId } = await axios.get("/api/config/paypal");
            const script = document.createElement("script");
            script.type = "text/javascript";
            script.src = `https://www.paypal.com/sdk/js?client-id=${clientId}`;
            script.async = true;
            script.onload = () => {
                setSdkReady(true);
            };
            document.body.appendChild(script);
        };
        if (!order || successPay) {
            dispatch({ type: ORDER_PAY_RESET });
            dispatch(getOrderDetails(orderId));
        } else if (!order.isPaid) {
            if (!window.paypal) {
                addPayPalScript();
            } else {
                setSdkReady(true);
            }
        }
    }, [dispatch, orderId, successPay, order]);

    const successPaymentHandler = (paymentResult) => {
        dispatch(payOrder(orderId, paymentResult));
    };

    return (
        <>
            <Navbar />
            <PageHeader headertitle="Commande placé à payer" />

            <div className="checkout-page-area pd-top-50">
                <div className="container">
                    <div className="row">
                        {loading ? (
                            <Loading />
                        ) : error ? (
                            <Message variant="alert-danger">{error}</Message>
                        ) : (
                            <>
                                <div className="col-lg-7">
                                    <div className="row  order-detail">
                                        <div className="col-lg-4 col-sm-3 mb-lg-4 mb-2 mb-sm-0">
                                            <div className="row">
                                                <div className="col-md-4 center" style={{ marginLeft:"10px" }}>
                                                    <div className="alert-success order-box">
                                                        <i className="fa fa-user"></i>
                                                    </div>
                                                </div>
                                                <div className="col-md-8 center">
                                                    <h5>
                                                        <strong>Customer</strong>
                                                    </h5>
                                                    <p>{order.user.name}</p>
                                                    <p>
                                                        <a href={`mailto:${order.user.email}`}>
                                                            {order.user.email}
                                                        </a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* 2 */}
                                        <div className="col-lg-4 col-sm-3 mb-lg-4 mb-2 mb-sm-0">
                                            <div className="row">
                                                <div className="col-md-4 center">
                                                    <div className="alert-success order-box">
                                                        <i className="fa fa-truck"></i>
                                                    </div>
                                                </div>
                                                <div className="col-md-8 center">
                                                    <h5>
                                                        <strong>Order info</strong>
                                                    </h5>
                                                    <p>Shipping: {order.shippingAddress.country}</p>
                                                    <p>Pay method: {order.paymentMethod}</p>
                                                    {order.isPaid ? (
                                                        <div className="bg-info p-2 col-12">
                                                            <p className="text-white text-center text-sm-start">
                                                                Paid on {moment(order.paidAt).calendar()}
                                                            </p>
                                                        </div>
                                                    ) : (
                                                        <div className="bg-danger p-2 col-12">
                                                            <p className="text-white text-center text-sm-start">
                                                                Not Paid
                                                            </p>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        {/* 3 */}
                                        <div className="col-lg-4 col-sm-4 mb-lg-4 mb-2 mb-sm-0">
                                            <div className="row">
                                                <div className="col-md-4 center">
                                                    <div className="alert-success order-box">
                                                        <i className="fa fa-map-marker"></i>
                                                    </div>
                                                </div>
                                                <div className="col-md-8 center">
                                                    <h5>
                                                        <strong>Deliver to</strong>
                                                    </h5>
                                                    <p>
                                                        Address: {order.shippingAddress.city},{" "}
                                                        {order.shippingAddress.address},{" "}
                                                        {order.shippingAddress.postalCode}
                                                    </p>
                                                    {order.isDelivered ? (
                                                        <div className="bg-info p-2 col-12">
                                                            <p className="text-white text-center text-sm-start">
                                                                Delivered on {moment(order.deliveredAt).calendar()}
                                                            </p>
                                                        </div>
                                                    ) : (
                                                        <div className="bg-danger p-2 col-12">
                                                            <p className="text-white text-center text-sm-start">
                                                                Not Delivered
                                                            </p>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>



                                <div className="col-lg-5">
                                    <div className="checkout-form-product">

                                        {order.orderItems.length === 0 ? (
                                            <Message variant="alert-info mt-5">Your cart is empty</Message>
                                        ) : (
                                            <>
                                                {order.orderItems.map((item, index) => (

                                                    <div className="order-table table-responsive" key={index}>
                                                        <table className="table">
                                                            <tbody>
                                                            <tr>
                                                                <td>
                                                                    <div className="media single-cart-product">
                                                                        <div className="media-left">
                                                                            <img src={item.image} style={{ width:"20%" }} alt={item.name}  />
                                                                        </div>
                                                                        <div className="media-body">
                                                                            <span>{item.name}</span>
                                                                            <p>Quantité : {item.qty}</p>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td className="cart-product-price text-center">${item.qty * item.price}</td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                ))}
                                            </>
                                        )}
                                        {/* total */}
                                        <div className="total-shapping-area-wrap">
                                            <div className="total-shapping-area">
                                                <div className="charge">
                                                    <span>Produits :</span>
                                                    <span className="amount float-right">${order.itemsPrice}</span>
                                                </div>
                                                <div className="charge">
                                                    <span>Adresse :</span>
                                                    <span className="amount float-right">${order.shippingPrice}</span>
                                                </div>
                                                <div className="charge">
                                                    <span>Taxes :</span>
                                                    <span className="amount float-right">${order.taxPrice}</span>
                                                </div>
                                                <div className="total-amount">
                                                    <span>Total:</span>
                                                    <span className="amount float-right">${order.totalPrice}</span>
                                                </div>
                                                {!order.isPaid && (
                                                    <div className="col-12">
                                                        {loadingPay && <Loading />}
                                                        {!sdkReady ? (
                                                            <Loading />
                                                        ) : (
                                                            <PayPalButton
                                                                amount={order.totalPrice}
                                                                onSuccess={successPaymentHandler}
                                                            />
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>


            <FooterV2 />

        </>
    );
}

export default Order

