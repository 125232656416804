import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ProfileTabs from "./profileComponents/ProfileTabs";
import Orders from "./profileComponents/Orders";
import moment from "moment";
import {listMyOrders} from "../../Redux/Actions/OrderActions";
import {getUserDetails} from "../../Redux/Actions/userActions";
import Navbar from "../global-components/navbar";
import "./Styleprofile.css";
import "./profileresponsive.css";
import Footer_V2 from "../global-components/footer-v2";

import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';

const ProfileScreen = () => {

  let publicUrl = process.env.PUBLIC_URL+'/'

  window.scrollTo(0, 0);

  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const orderListMy = useSelector((state) => state.orderListMy);
  const { loading, error, orders } = orderListMy;

  useEffect(() => {
    dispatch(listMyOrders());
    dispatch(getUserDetails("profile"));
  }, [dispatch]);

  return (
    <>
      <Navbar  />

      <div className="container pd-top-120">

        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
          <Row>
            <Col sm={4}>
              <div className="author-card pd-top-50">
                <div className="author-card-cover"></div>
                <div className="author-card-profile row">
                  <div className="author-card-avatar ">
                    <img src={`${publicUrl}assets/img/user.png`} alt="userprofileimage" />
                  </div>
                  <div className="author-card-details col-md-7">
                    <h5 className="author-card-name mb-2">
                      <strong>{userInfo.name}</strong>
                    </h5>
                    <span className="author-card-position">
                    <>Joined {moment(userInfo.createdAt).format("LL")}</>
                  </span>
                  </div>
                </div>
              </div>
              <div className="wizard pt-3 ">
                <div className="d-flex align-items-start">
                  <div
                      className="align-items-start flex-column col-12 me-3 "
                  >
                    <Nav variant="pills" className="flex-column">
                      <Nav.Item>
                        <Nav.Link eventKey="first">Profile Settings</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="second">Orders List <span className="badge2">{orders ? orders.length : 0}</span> </Nav.Link>
                      </Nav.Item>
                    </Nav>

                  </div>
                </div>
              </div>

            </Col>

            <Col sm={8}>
              <Tab.Content>
                <Tab.Pane eventKey="first">
                  <ProfileTabs />

                </Tab.Pane>
                <Tab.Pane eventKey="second">
                  <Orders orders={orders} loading={loading} error={error} />

                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>

      </div>

      <Footer_V2 />

    </>
  );
};

export default ProfileScreen;
