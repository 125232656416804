import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import VideoV2 from './section-components/video-v2';
import WhatWeDo from './section-components/what-we-do';
import TestimonialV2 from './section-components/testimonial-v2';
import Team from './section-components/team';
import Subscribe from './section-components/subscribe';
import Footer_v1 from "./global-components/footer-v1";
import Footer_V2 from "./global-components/footer-v2";

const About = () => {
    return <div>
        <Navbar />
        <PageHeader headertitle="A propos" />
        <VideoV2 customclass="pd-top-120 bg-none" />
        <WhatWeDo customclass="pd-top-80"/>
        <Team />
        {/*<TestimonialV2 />*/}
        <Subscribe />
        <Footer_V2 />
    </div>
}

export default About

