import React, { Component } from 'react';
import sectiondata from '../../data/sections.json';
import parse from 'html-react-parser';


class Map extends Component {
  render() {
    return <div>
      <div className="map-area pd-top-50">
        <div className="container">
          <div className="map-area-wrap">
            <div className="row no-gutters">
              <div className="col-lg-12">
                <iframe title="riyaqas-map" width="100%" height="440" src="https://maps.google.com/maps?width=700&amp;height=440&amp;hl=en&amp;q=London%2C%20United%20Kingdom+(Title)&amp;ie=UTF8&amp;t=&amp;z=10&amp;iwloc=B&amp;output=embed" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  }
}

export default Map