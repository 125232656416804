import React from 'react';
import Navbar from './global-components/navbar';
import ContactForm from './section-components/contact-formi';
import Footer_v1 from "./global-components/footer-v1";
import Pricing from './section-components/pricing-page';
import Footer_V2 from "./global-components/footer-v2";
import { useParams } from 'react-router';
import {NavLink} from "react-router-dom";


const Inscriptionn = () => {
    const para = useParams();

    return <div>
        <Navbar />

        <div className="pricing-page-area pd-top-112">
            <div className="container">
                <div className="row no-gutters justify-content-center">
                    <div className="col-lg-6" style={{ animation:"1s 1s fadeInUp both" }}>
                        <div className="col-md-12 text-center"  style={{ fontSize:"18px" }}>
                            CREEZ VOTRE COMPTE
                            <hr style={{ width:"120px" }} />
                        </div>
                        <br/>
                        <form className="riyaqas-form-wrap mt-0 mt-lg-0">
                            <div className="row custom-gutters-16">

                                <div className="col-md-6">
                                    <div className="single-input-wrap">
                                        <input type="text" className="single-input form-control"/>
                                        <label>Nom*</label>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="single-input-wrap">
                                        <input type="text" className="single-input"/>
                                        <label>Prénom*</label>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="single-input-wrap">
                                        <input type="email" className="single-input"/>
                                        <label>E-mail*</label>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="single-input-wrap">
                                        <input type="file" className="single-input"/>
                                        <label>Photo de profil*</label>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="single-input-wrap">
                                        <input type="text" className="single-input"/>
                                        <label>Mot de passe :*</label>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="single-input-wrap">
                                        <input type="text" className="single-input"/>
                                        <label>Confirmer le mot de passe*</label>
                                    </div>
                                </div>
                                <div className="col-md-12 text-center">
                                    <a className="btn btn-red mt-0" href="#">Créer votre compte</a>
                                </div>
                            </div>
                        </form>
                        <div className="col-md-12 text-center mg-top-45">
                            Vous avez déjà un compte ? <NavLink to="/connexion">Connectez-vous</NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Footer_V2 />
    </div>
}

export default Inscriptionn

