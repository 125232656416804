import axios from "axios";
import {
  CATEGORY_CREATE_REVIEW_FAIL,
  CATEGORY_CREATE_REVIEW_REQUEST,
  CATEGORY_CREATE_REVIEW_SUCCESS,
  CATEGORY_DETAILS_FAIL,
  CATEGORY_DETAILS_REQUEST,
  CATEGORY_DETAILS_SUCCESS,
  CATEGORY_LIST_FAIL,
  CATEGORY_LIST_REQUEST,
  CATEGORY_LIST_SUCCESS,
} from "../Constants/CategoryConstants";
import { logout } from "./userActions";

// CATEGORY LIST
export const listCategorys =
    (keyword = " ", pageNumber = " ") =>
        async (dispatch) => {
          try {
            dispatch({ type: CATEGORY_LIST_REQUEST });
            const { data } = await axios.get(
                `https://nz-company.herokuapp.com/api/categories/`
            );
            dispatch({ type: CATEGORY_LIST_SUCCESS, payload: data });
          } catch (error) {
            dispatch({
              type: CATEGORY_LIST_FAIL,
              payload:
                  error.response && error.response.data.message
                      ? error.response.data.message
                      : error.message,
            });
          }
        };

// SINGLE CATEGORY
export const listCategoryDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: CATEGORY_DETAILS_REQUEST });
    const { data } = await axios.get(`https://nz-company.herokuapp.com/api/categories/${id}`);
    dispatch({ type: CATEGORY_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: CATEGORY_DETAILS_FAIL,
      payload:
          error.response && error.response.data.message
              ? error.response.data.message
              : error.message,
    });
  }
};