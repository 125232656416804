import React, { useEffect } from "react";
import sectiondata from '../../data/product.json';
import { Link } from 'react-router-dom';
import ProductSidebar from '../section-components/product-sidebar';
import {useDispatch, useSelector} from "react-redux";
import {listProduct} from "../../Redux/Actions/ProductActions";
import Pagination from "./pagination";
import Rating from "./Rating";
import Loading from "../LoadingError/Loading";
import Message from "../LoadingError/Error";

const ShopPage = (props) => {
    const { keyword, pagenumber } = props;

    const dispatch = useDispatch();

    const productList = useSelector((state) => state.productList);
    const { loading, error, products, page, pages } = productList;

    useEffect(() => {
        dispatch(listProduct(keyword, pagenumber));
    }, [dispatch, keyword, pagenumber]);

    const $ = window.$;

    if ($('.product-select').length){
        $('.product-select').niceSelect();
    }

  let publicUrl = process.env.PUBLIC_URL+'/'
    let imgattr = 'image'
    let data = sectiondata.shop

    return (
        <>
            <div>
                <div className="shop-page-area pd-top-80">
                    <div className="container">
                        <div className="row custom-gutters-60">
                            <ProductSidebar />

                            <div className="col-lg-8">
                                <div className="row justify-content-center">

                                    <div className="col-sm-12 mg-bottom-30">
                                        <select className="select woocommerce-sorting-select float-sm-right">
                                            <option value={1}>Tri par défaut</option>
                                            <option value={2}>Trier par récent</option>
                                            <option value={3}>Trier par popularité</option>
                                        </select>
                                    </div>
                                    {loading ? (
                                        <div className="mb-5">
                                            <Loading />
                                        </div>
                                    ) : error ? (
                                        <Message variant="alert-danger">{error}</Message>
                                    ) : (
                                        <>

                                    { products?.map( ( product, i )=>
                                        <div key={ i } className="col-lg-4 col-sm-6">
                                            <div className="single-shop">
                                                <Link to={`/shop-details/${product._id}`}>
                                                    <div className="thumb">
                                                        <img src={product.image} alt={product.name} />
                                                    </div>
                                                </Link>
                                                <div className="content">
                                                    <div className="rating">
                                                        <Rating
                                                            value={product.rating}
                                                            text={`${product.numReviews} reviews`}
                                                        />
                                                    </div>
                                                    <Link to={`/shop-details/${product._id}`}>{product.name}</Link>
                                                    <div className="price">
                                                        <span>${product.price}</span>
                                                        {/*<del>{ item.olderprice }</del>*/}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) }

                                        </>
                                    )}

                                    <div className="col-12">
                                        <div className="riyaqas-pagination text-center margin-top-60">
                                            {/* Pagination */}
                                            <Pagination
                                                pages={pages}
                                                page={page}
                                            />
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

  }

export default ShopPage