import React, { Component } from 'react';
import sectiondata from '../../data/sections.json';
import {Link, NavLink} from "react-router-dom";

class Team extends Component {

    render() {
        let publicUrl = process.env.PUBLIC_URL+'/'
        let imgattr = 'image'
        let customclass = this.props.customclass ? this.props.customclass : ''

    return <div>
            <div className={ " "+customclass }>
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-xl-6 col-lg-9">
                    <div className="section-title text-center">
                      <h2 className="title">
                        { sectiondata.pricingtable.sectiontitle } <span>{sectiondata.pricingtable.sectiontitle_color}</span>
                      </h2>
                      <p>
                       {sectiondata.pricingtable.short_description}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row custom-gutters-20 justify-content-center">
                    {sectiondata.pricingtable.tables.map( ( item, i ) =>
                        <div key={i} className="col-xl-3 col-sm-6">
                            <div className="single-pricing text-center">
                                <h6 className="title">{item.title}</h6>
                                <div className="thumb">
                                    <img src={publicUrl+item.icon} alt={imgattr} />
                                </div>
                                <h3 className="price">{item.price} <span>{item.duration}</span></h3>
                                <ul>
                                {item.features.map( ( feature, i  ) =>
                                 <li key={i}>{feature}</li>
                                )}
                                </ul>
                                <Link className="btn btn-white btn-rounded"  to={ item.url }>Souscrire</Link>
                            </div>
                        </div>
                    )}

                </div>
              </div>
            </div>
            <div className="pricing-page-area pd-top-112">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-xl-6">
                    <div className="section-title text-center">
                      <h2 className="title">
                        Vous êtes clients du site ? <span>Inscrivez-vous pour vos achats et vos transactions</span>
                      </h2>
                      <p>
                       Payez différents produits , faites vous accompagner dans vos besoins de transactions d'argent dans le monde
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row no-gutters justify-content-center">
                    <div className="col-lg-6" style={{ animation:"1s 1s fadeInUp both" }}>
                        <div className="col-md-12 text-center"  style={{ fontSize:"18px" }}>
                            CREEZ VOTRE COMPTE
                            <hr style={{ width:"120px" }} />
                        </div>
                        <br/>
                        <form className="riyaqas-form-wrap mt-0 mt-lg-0">
                            <div className="row custom-gutters-16">

                                <div className="col-md-6">
                                    <div className="single-input-wrap">
                                        <input type="text" className="single-input form-control"/>
                                        <label>Nom*</label>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="single-input-wrap">
                                        <input type="text" className="single-input"/>
                                        <label>Prénom*</label>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="single-input-wrap">
                                        <input type="email" className="single-input"/>
                                        <label>E-mail*</label>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="single-input-wrap">
                                        <input type="file" className="single-input"/>
                                        <label>Photo de profil*</label>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="single-input-wrap">
                                        <input type="text" className="single-input"/>
                                        <label>Mot de passe :*</label>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="single-input-wrap">
                                        <input type="text" className="single-input"/>
                                        <label>Confirmer le mot de passe*</label>
                                    </div>
                                </div>
                                <div className="col-md-12 text-center">
                                    <a className="btn btn-red mt-0" href="#">Créer votre compte</a>
                                </div>
                            </div>
                        </form>
                        <div className="col-md-12 text-center mg-top-45">
                            Vous avez déjà un compte ? <NavLink to="/connexion">Connectez-vous</NavLink>
                        </div>
                    </div>
                </div>
              </div>
            </div>
        </div>
    }
}

export default Team