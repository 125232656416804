import React from 'react';
import Navbar from './global-components/navbar';
import BannervDomain from './section-components/banner-domain';
import Hosting from './section-components/hosting';
import Map from './section-components/map';
import FooterV1 from './global-components/footer-v1';

const Recherche = () => {
    return <div>
        <Navbar />
        <BannervDomain />
        <Hosting />
        <Map />
        <FooterV1 />
    </div>
}

export default Recherche

