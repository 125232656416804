import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import ContactForm from './section-components/contact-form';
import ContactInfo from './section-components/contact-info';
import Footer_v1 from "./global-components/footer-v1";
import Footer_V2 from "./global-components/footer-v2";

const Contact = () => {
    return <div>
        <Navbar />
        <PageHeader headertitle="Contact" />
        <ContactForm />
        <ContactInfo />
        <Footer_V2 />
    </div>
}

export default Contact

