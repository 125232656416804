import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Navbar from './global-components/navbar';
import ContactForm from './section-components/contact-formc';
import Footer_v1 from "./global-components/footer-v1";
import Footer_V2 from "./global-components/footer-v2";
import {login} from "../Redux/Actions/userActions";
import Message from "../components/LoadingError/Error";
import Loading from "../components/LoadingError/Loading";
import {NavLink} from "react-router-dom";

const Connexion = ({ location, history }) => {
    window.scrollTo(0, 0);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const dispatch = useDispatch();
    const redirect = location.search ? location.search.split("=")[1] : "/";

    const userLogin = useSelector((state) => state.userLogin);
    const { error, loading, userInfo } = userLogin;

    useEffect(() => {
        if (userInfo) {
            history.push(redirect);
        }
    }, [userInfo, history, redirect]);

    const submitHandler = (e) => {
        e.preventDefault();
        dispatch(login(email, password));
    };

    return (
        <>
            <Navbar />

            <div className="contact-form-area pd-top-190">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-4 connectbox" style={{ animation:"1s 1s fadeInUp both" }}>
                            <div className="col-md-12 text-center" style={{ fontSize:"18px" }}>
                                CONNECTEZ-VOUS
                                <hr style={{ width:"120px" }} />
                            </div>
                            <br/>
                            {error && <Message variant="alert-danger">{error}</Message>}
                            {loading && <Loading />}
                            <form className="riyaqas-form-wrap mt-0 mt-lg-0" onSubmit={submitHandler}>
                                <div className="row custom-gutters-16">

                                    <div className="col-md-12">
                                        <div className="single-input-wrap">
                                            <input
                                                type="email"
                                                className="form-control"
                                                placeholder="Email"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                            <label>E-mail*</label>
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="single-input-wrap">
                                            <input
                                                type="password"
                                                className="form-control"
                                                placeholder="Password"
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                            />
                                            <label>Mot de passe :*</label>
                                        </div>
                                    </div>
                                    {/*<div className="col-md-12 text-right">
                                        <a href={"/mdp"} className="aconnect">Mot de passe oublié ?</a>
                                    </div>*/}
                                    <div className="col-md-12 pd-top-20 text-center form-control">
                                        <button className="btn btn-red mt-0 form-control" type="submit">Connexion</button>
                                    </div>
                                    {/*<div className="col-md-12 text-center">
                                        se connecter avec
                                    </div>
                                    <div className="col-md-12 text-center">
                                        <div className="row">
                                            <div className="col-md-6 text-right">
                                                <i style={{ fontSize:"40px" }} className="fa fa-facebook" />
                                            </div>
                                            <div className="col-md-6 text-left">
                                                <i style={{ fontSize:"40px" }} className="fa fa-google" />
                                            </div>
                                        </div>
                                    </div>*/}
                                    <div className="col-md-12 text-center form-control">
                                        Vous n'avez pas de compte ? <NavLink
                                        to={redirect ? `/inscription?redirect=${redirect}` : "/inscription"}
                                        className="aconnect">Inscrivez-vous dès maintenant</NavLink>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <Footer_V2 />
        </>
    );


}

export default Connexion

