import React from 'react';
import Errorpage from './section-components/error';

const Error = () => {
    return <div>
        <Errorpage />
    </div>
}

export default Error

